define("discourse/plugins/discourse-cakeday/discourse/controllers/cakeday-birthdays-upcoming", ["exports", "@ember/controller", "discourse-common/utils/decorators", "I18n"], function (_exports, _controller, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend((_obj = {
    title() {
      const dateFormat = _I18n.default.t("dates.full_no_year_no_time");
      return _I18n.default.t("birthdays.upcoming.title", {
        start_date: moment().add(2, "days").format(dateFormat),
        end_date: moment().add(2, "days").add(1, "week").format(dateFormat)
      });
    },
    actions: {
      loadMore() {
        this.get("model").loadMore();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "title", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "title"), _obj)), _obj));
});